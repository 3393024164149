<template>
  <div class="UseDailyPracticeRecord"  :style="{minHeight: screeHeight+'px'}">
    <div class="Previous_left">
        <!-- <a-menu v-model="selectId" mode="inline" style="width: 100%"> -->
      <a-menu
        @select="selectChange"
        :selectedKeys="selectedKeys"
        :style="{minHeight: screeHeight+'px'}"
        mode="inline"
      >
        <a-menu-item key="">
          <span>全部</span>
        </a-menu-item>
        <!-- <a-menu-item key="0">
          <span>常用公式</span>
        </a-menu-item> -->
        <!-- <a-menu-item key="DWHS">
          <span>单位换算</span>
        </a-menu-item> -->
          <a-menu-item v-for="item in menuList" :key="item.code">
                <div style="display:flex;justify-content: space-between">
                   <span>{{ item.title }}</span>              
                </div>
               
              </a-menu-item>
      </a-menu>
        <!-- </a-menu> -->
    </div>
    <div class="Previous_right">
      <div class="log">
        <a-spin :spinning="spinning">
          <div class="search">
            <div>
              使用日期&nbsp;
              <a-range-picker v-model="searchValue" class="rangePicker" @change="dateChange"/>
              <a-button style="margin-left: 20px" type="primary" @click="searchBtn">搜索</a-button>
            </div>
            <a-button @click="expotExcel">
              <a-icon style="color: #00A854;" type="file-excel"/>
              批量导出
            </a-button>
          </div>
          <div class="table">
            <a-table
                :columns="columns"
                :data-source="table"
                :pagination="false"
                :rowKey="record=>record.id"
                :scroll="{ x: 1700}">
              <span slot="isDeleted" slot-scope="text, record">
                <span v-if="record.isDeleted == '0'">正常</span>
                <span v-if="record.isDeleted == '1'">已删除</span>
              </span>
            </a-table>
            <!--分页-->
            <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination v-model="pagination.current"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="pagination.pageSize"
                            :total="pagination.total"
                            show-quick-jumper show-size-changer
                            @change="pageChange"
                            @showSizeChange="pageChange"/>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { getType1,getMenuList } from "@/service/MedicalFormulas_api";
import {
  getFormulaRecord,getFormulaRecordExport
} from "@/service/MedicalFormulas_api";
import {
  getColumnVodsList,
} from "@/service/MedicalConference_y";
export default {
  data(){
    return{
      selectedKeys: [''],
      menuList: [],
      columns: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'name',
        },
        {
          title: '医院',
          align: 'center',
          dataIndex: 'hospitalName',
        }, {
          title: '科室',
          dataIndex: 'hospitalOffices',
          align: 'center',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          align: 'center',
        },
        {
          title: '栏目名称',
        //   scopedSlots: {customRender: 'targetTitle'},
          align: 'center',
          dataIndex: 'title',
          // scopedSlots: {customRender: 'columnCode'}
        },
        {
          title: '公式名称',
          align: 'center',
          dataIndex: 'chineseName',
        },
        {
          title: '渠道',
          align: 'center',
          dataIndex: 'channel',
          // scopedSlots: {customRender: "channel"},
        },
        {
          title: '使用位置',
          align: 'center',
          dataIndex: 'location',
          // scopedSlots: {customRender: "channel"},
        },
        {
          title: '选择患者',
          align: 'center',
          dataIndex: 'patientState',
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'isDeleted',
          scopedSlots: {customRender: "isDeleted"},
        },
        {
          title: '使用时间',
          align: 'center',
          dataIndex: 'createdTime',
        },
      ],
      table:[],
      pageSizeOptions: ['10', '30', '50'],
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      spinning:false,
      searchValue: [],
      screeHeight: document.body.clientHeight-64-15,
      templateList:[
        {
          code:"",
          name:"全部"
        },
        {
          code:"0",
          name:"常用公式"
        },
        {
          code:"DWHS",
          name:"单位换算"
        },
      ],
      selectId: [""],
    }
  },
  created(){
    this.getData()
    this.getMenuList()
  },
  methods:{
     async getMenuList() {
      const res = await getType1(1,999,"");
      if (res.code === 0) {
        // const index = res.data.findIndex((item) => item.code === "YUN_ICU");
        // res.data.splice(index, 1);
        this.menuList = res.data;
        console.log(this.menuList);
      }
    },
    async getData() {
        this.spinning = true;
        let data = {
          columnCode: this.selectedKeys[0],
          startTime: this.searchValue[0],
          endTime: this.searchValue[1],
          pageNo: this.pagination.current,  
          pageSum:this.pagination.pageSize,  
        };
        const response = await getFormulaRecord(data);
        if (response.code === 0) {
          // console.log("医学公式",response.data)
          this.table = response.data;
          this.pagination.total = response.count;
        } else {
          this.$message.warning(response.message);
        }
        this.spinning = false;
    },
    // 菜单
    selectChange (e) {
      this.selectedKeys = e.selectedKeys
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.searchValue = []
      this.getData()
    },
    // 日期
    dateChange(date, dateString) {
      this.pagination.current = 1
      this.searchValue = dateString
      console.log("日期",this.searchValue)
      this.getData()
    },
    searchBtn() {
    //   this.getData()
    },
    pageChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getData()
    },
    // 导出
    async expotExcel() {
      const data = {
        columnCode: this.selectedKeys[0],
        startTime: this.searchValue[0],
        endTime: this.searchValue[1],
        ExelName: "医学公式使用记录",
      };
      const response = await getFormulaRecordExport(data);
    },
  }
}
</script>


<style lang="scss" scoped>
.ant-menu {
  border: none;
}

.UseDailyPracticeRecord {
  margin: -15px -15px;
  display: flex;
  justify-content: space-between;

  .Previous_left {
    flex: 1;
    //background-color: #ee7474;
    .Previous_module {
      color: black;
      font-weight: bolder;
      margin-left: 25px;
      margin-top: 10px;
    }

  }

  .Previous_right {
    border-left: 15px solid #F0F2F5;
    width: 84%;

    .Previous_right_top {
    }

    .Previous_right_title {
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }

    .top_cont {
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }

    .EduRec-top-input {
      height: 50px;
      padding-top: 15px;
    }

    .activity-table {
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }

    .pageView {
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .pageLeft {
      font-size: 15px;
      color: #929292
    }
  }
}

.log {
  background-color: #f0f2f5;

  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 10px;
  }

  .table {
    background-color: #fff;
    padding: 20px;
  }
}

.pageView {
  display: flex;
  justify-content: space-between;
  z-index: 3;
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep .ant-table-thead>tr>th {
  font-weight: bold;

}
.pageLeft {
  font-size: 15px;
  color: #929292
}
</style>